import { RootState } from '@app/store';
import { USER_SESSION_TOKEN } from '@common/constants';
import { IUserBasketRatio } from '@features/auth/api/authApi.type';
import { IAuthState } from '@features/auth/slice/authSlice.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reactLocalStorage } from 'reactjs-localstorage';
import { authApi } from '../api/authApi';

const formatInterest = (value: number) => {
  if (value) {
    const interestValue = Number(value) / 100;
    if (!isNaN(interestValue)) {
      return interestValue;
    }
  }
  return 0;
};
const storedToken = reactLocalStorage.get<string | null>(USER_SESSION_TOKEN, null);
const initialState: IAuthState = {
  user: null,
  token: storedToken,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setBasketRatio: (state, action: PayloadAction<IUserBasketRatio>) => {
      if (state.token && state.user) {
        state.user.stats.basketRatio = action.payload;
      }
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        state.user = payload;
        state.user.stats = {
          ...payload.stats,
          liveInterest: formatInterest(payload.stats.liveInterest),
          prematchInterest: formatInterest(payload.stats.prematchInterest),
        };
      })
      .addMatcher(authApi.endpoints.checkToken.matchFulfilled, (state, { payload }) => {
        state.user = payload;
        state.user.stats = {
          ...payload.stats,
          liveInterest: formatInterest(payload.stats.liveInterest),
          prematchInterest: formatInterest(payload.stats.prematchInterest),
        };
      });
  },
});

export const userLoggedIn = (state: RootState) => !!(state.auth.token && state.auth.user);
export const getUserId = (state: RootState) => state.auth.user?.id;
export const getInterest = (type: 'live' | 'prematch') => (state: RootState) => {
  return state.auth.user?.stats[`${type}Interest`] || 0;
};
export const selectUserCurrencyCode = (state: RootState) => state.auth.user?.currency.code;
export const selectUserCountryCode = (state: RootState) => state.auth.user?.country.code;

export const selectUserBalance = (state: RootState) => {
  const balance = state.auth?.user?.currency.balance;
  if (balance) {
    return parseFloat(balance);
  }
  return 0;
};

export const { setBasketRatio, logout } = authSlice.actions;
export default authSlice.reducer;
