import Button from '@mui/material/Button';
import { SnackbarKey } from 'notistack';
import { FormattedMessage } from 'react-intl';

const snackbarActions = (closeSnackbar: (key: SnackbarKey) => void) => (snackbarId: SnackbarKey) => (
  <Button sx={{ color: 'white' }} onClick={() => closeSnackbar(snackbarId)}>
    <FormattedMessage id="button.dismiss"/>
  </Button>
);

export default snackbarActions;
