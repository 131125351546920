export const iosBridgeGetAppVersion = (fncCallback: (value: string) => void) => {
  if (window?.webkit?.messageHandlers?.jsIOSAppVersion) {
    if (!window.actions || !window.actions.hasOwnProperty('jsIOSAppVersion')) {
      window.actions = {
        jsIOSAppVersion1: fncCallback,
      };
    }
    window.webkit.messageHandlers.jsIOSAppVersion.postMessage('jsIOSAppVersion1');
  }
};

export const iosBridgeCheckCameraPermission = () => {
  if (window?.webkit?.messageHandlers?.jsCheckCameraPermission) {
    window.webkit.messageHandlers.jsCheckCameraPermission.postMessage('Check camera permission');
  }
};

export const iosBridgeRegisterUserId = (userId: number) => {
  if (window?.webkit?.messageHandlers?.jsRegisterUserId) {
    window.webkit.messageHandlers.jsRegisterUserId.postMessage(userId);
  }
};

export const iosBridgeUnregisterUserId = (userId: number) => {
  if (window?.webkit?.messageHandlers?.jsUnregisterUserId) {
    window.webkit.messageHandlers.jsUnregisterUserId.postMessage(userId);
  }
};

export const iosBridgeSignUpSuccess = (userId: number) => {
  if (window?.webkit?.messageHandlers?.jsSignUpSuccess) {
    window.webkit.messageHandlers.jsSignUpSuccess.postMessage(userId);
  }
};
