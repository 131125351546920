import { commonApi } from '@common/utils/baseQuery';
import config from '@config';
import type { IResponseGiftBox, IResponseOpenGiftBox } from '@features/giftbox/api/giftboxApi.type';

export const giftBoxApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchGiftBoxes: builder.query<IResponseGiftBox, void>({
      query: () => `${config.host}/giftbox`,
    }),
    openGiftBox: builder.mutation<IResponseOpenGiftBox, string | number>({
      query: (id) => ({
        url: `${config.host}/giftbox/${id}`,
        method: 'PUT',
      }),
    }),
  }),
});
export const {
  useFetchGiftBoxesQuery,
  useOpenGiftBoxMutation,
} = giftBoxApi;
